.logo {
  display: block;
}

.logo > * {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  object-fit: contain;
}
