body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

*::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: transparent;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}

@keyframes pulse {
  0% {
    opacity: 0.2;
  }

  100% {
    opacity: 0.6;
  }
}

*::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #999;
}

body > *:first-child {
  min-height: 100vh;
}

@media screen {
  .print {
    display: none;
  }
}

@media print {
  body {
    min-height: auto;
  }
  .screen {
    display: none;
  }
  .print > *:not(:last-child) {
    page-break-after: always;
  }
  .print * {
    color: black;
  }
}
